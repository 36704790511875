<template>

    <div class="row">
        <div class="col-lg-6 col-xl-2 order-lg-1 order-xl-1">
            <!-- profile summary -->
            <div class="card mb-g rounded-top">
                <div class="row no-gutters row-grid">
                    <div class="col-12">
                        <div class="d-flex flex-column align-items-center justify-content-center p-4">
                            <div class="position-relative">
                                <img :src="profile.image ? profile.image : publicPath + 'media/default-avatar.png'" class="rounded-circle shadow-2 img-thumbnail" alt="">
                                <label href="javascript:void(0);" for="upload"
                                       class="upload-avatar-icon position-absolute pos-bottom pos-right"
                                       data-toggle="tooltip" data-original-title="Insert photo" data-placement="top">
                                    <i class="fal fa-camera color-fusion-300"></i>
                                </label>
                                <input id="upload" class="hidden" ref="input" type="file" name="image" accept="image/*"
                                       @input="loadImage($event)" />
                            </div>
                            <h5 class="mb-0 fw-700 text-center mt-3">
                                {{ currentUser.name }}
                                <small class="text-muted mb-0">{{ currentUser.email }}</small>
                            </h5>
                        </div>
                    </div>
                    <!--Filter accordions-->
                    <div id="panel-2" class="w-100">
                        <div class="panel-container">
                            <div class="panel-content">
                                <div class="border bg-light rounded-top">
                                    <div id="js_list_accordion" class="accordion accordion-hover accordion-clean">
                                        <div @click="changeActive('information')" class="card ">
                                            <div class="card-header">
                                                <a href="javascript:void(0);" class="card-title"
                                                   v-bind:class="{ collapsed : !active.information}">
                                                    <i class="fal fa-cog width-2 fs-xl"></i>
                                                    Thông tin chi tiết
                                                </a>
                                            </div>
                                        </div>
                                        <div @click="changeActive('identity')" class="card">
                                            <div class="card-header">
                                                <a href="javascript:void(0);" class="card-title"
                                                   v-bind:class="{ collapsed : !active.identity}">
                                                    <i class="fal fa-id-card width-2 fs-xl"></i>
                                                    Xác minh danh tính
                                                </a>
                                            </div>
                                        </div>
                                        <div v-if="checkAccount(currentUser.profile.account_type_id)" @click="changeActive('managementBots')" class="card">
                                            <div class="card-header">
                                                <a href="javascript:void(0);" class="card-title"
                                                   v-bind:class="{ collapsed : !active.managementBots}">
                                                    <i class="fal fa-id-card width-2 fs-xl"></i>
                                                    Quản lý bots
                                                </a>
                                            </div>
                                        </div>
                                        <div @click="changeActive('qrcode')" class="card">
                                            <div class="card-header">
                                                <a href="javascript:void(0);" class="card-title"
                                                   v-bind:class="{ collapsed : !active.qrcode}">
                                                    <i class="fal fa-qrcode width-2 fs-xl"></i>
                                                    Mã QR
                                                </a>
                                            </div>
                                        </div>
                                        <div @click="changeActive('changePassword')" class="card">
                                            <div class="card-header">
                                                <a href="javascript:void(0);" class="card-title"
                                                   v-bind:class="{ collapsed : !active.changePassword}">
                                                    <i class="fal fa-cloud-upload-alt width-2 fs-xl"></i>
                                                    Thay đổi mật khẩu
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 order-lg-3 order-xl-2" :class="{'col-xl-10': active.identity || active.managementBots, 'col-xl-2': active.qrcode, 'col-xl-6': active.information || active.changePassword }">
            <edutalk-information v-if="active.information"></edutalk-information>
            <edutalk-identity v-if="active.identity"></edutalk-identity>
            <edutalk-qrcode v-if="active.qrcode"></edutalk-qrcode>
            <edutalk-password v-if="active.changePassword"></edutalk-password>
            <management-bots-index v-if="active.managementBots"></management-bots-index>
        </div>
        <div :class="`col-lg-6 ${active.identity ? 'col-xl-0' : 'col-xl-4' } order-lg-2 order-xl-3`">
            <edutalk-bank v-if="active.information"></edutalk-bank>
            <edutalk-pancake v-if="is_sale && active.information"></edutalk-pancake>
        </div>
        <!--eslint-disable-->
        <edutalk-modal :closeOnClickModal="true"  @closed="closed" title="Thay đổi ảnh đại diện" :visible.sync="dialogFormVisible">
            <div class="row">
                <div class="col-lg-12 justify-content-edutalk">
                    <div class="edutalk-croper">
                        <div class="img-cropper">
                            <cropper
                                    ref="cropper"
                                    class="cropper"
                                    :src="image.src"
                                    @change="onChange"
                                    stencil-component="circle-stencil"
                                    :stencil-props="{
                                        aspectRatio: 1,
                                    }"
                                    :debounce="false"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Hủy</el-button>
                <el-button type="primary" class="btn-primary" @click="cropImage">Xác nhận</el-button>
            </span>
        </edutalk-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import EdutalkPassword from "@/views/pages/user/edutalk-password";
    import EdutalkIdentity from "@/views/pages/user/edutalk-identity";
    import EdutalkInformation from "@/views/pages/user/edutalk-information";
    import EdutalkBank from "@/views/pages/user/edutalk-brank";
    import EdutalkQrcode from "@/views/pages/user/edutalk-qrcode";
    import EdutalkPancake from "@/views/pages/user/edutalk-pancake";
    import ManagementBotsIndex from "../management-bots/ManagementBotsIndex.vue";
    import { Cropper } from 'vue-advanced-cropper'
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    import Swal from 'sweetalert2';

    import 'vue-advanced-cropper/dist/style.css';
    import 'vue-advanced-cropper/dist/theme.classic.css';

    import {mapGetters} from "vuex";
    import {USER_UPLOAD_IMAGE} from "@/core/services/store/profile.module";

    export default {
        name: "EdutalkProfile",
        components: {
            EdutalkPassword,
            EdutalkIdentity,
            EdutalkInformation,
            EdutalkBank,
            EdutalkPancake,
            EdutalkQrcode,
            Cropper,
            ManagementBotsIndex
        },
        data() {
            return {
              publicPath: process.env.VUE_APP_BASE_URL,
                profile: {
                    image: ''
                },
                active: {
                    information: true,
                    identity: false,
                    qrcode: false,
                    changePassword: false,
                    managementBots: false
                },
                dialogFormVisible: false,
                image: {
                    src: null,
                    type: null,
                },
                event: '',
                result: {
                    coordinates: null,
                    image: null
                },
                selected: [],
                options: [
                    {name: 'Nam', id: 1},
                    {name: 'Nữ', id: 0},
                ],
                is_sale : false
            }
        },
        mounted() {
          if (this.currentUser.profile.candidate && this.currentUser.profile?.candidate.status == 3) {
            this.active.identity = true;
            this.active.information = false;
          }
          this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Thông tin tài khoản", icon: 'far fa-home'}
            ]);
            this.profile.image = this.currentUser.image;
            let role_id = this.currentUser?.account_type?.id;
            if(role_id == '1' || role_id == '2' || role_id == '3' || role_id == '4') {
                this.is_sale = true;
            }
            this.checkHash();
            window.addEventListener('hashchange', this.checkHash);
        },
        beforeDestroy() {
            window.removeEventListener('hashchange', this.checkHash);
        },
        methods: {
            checkHash() {
                const hash = this.$route.query.active;
                console.log(hash, 'aaa');

                this.active = {
                    information: hash === '1',
                    identity: hash === '2',
                    qrcode: hash === '3',
                    changePassword: hash === '4',
                    managementBots: hash === '5'
                };
            },
            changeActive(tab) {
                let active = 1;
                switch (tab) {
                    case 'identity':
                        active = 2;
                        break;
                    case 'qrcode':
                    active = 3;
                        break;
                    case 'changePassword':
                        active = 4;
                        break;
                    case 'managementBots':
                        active = 5;
                        break;
                    default:
                        active = 1;
                        break;
                }
                this.$router.push({
                    name: 'profile',
                    query: {active: active}
                });
                if (tab === "information" || tab === "changePassword") {
                    let status_verify = parseInt(this.currentUser.profile?.status_verify);
                    let role_id = this.currentUser.profile?.account_type.noibo_role_id;
                    if(role_id == '30' || role_id == '31' || role_id == '32' || role_id == '33' || role_id == '37' || role_id == '38' ) {
                        if (status_verify !== 2 && status_verify !== 1) {
                            Swal.fire({
                                title: 'Bạn cần hoàn tất thủ tục xác minh danh tính',
                                icon: 'warning',
                                showDenyButton: false,
                                showCancelButton: false,
                                confirmButtonText: `Đồng ý`,
                            })
                            return false;
                        }
                    }
                }
                _.mapKeysDeep(this.active, (value, key) => {
                    return this.active[key] = key === tab;
                });
                console.log(this.active, tab, 'aaa');

            },
            onChange({ coordinates, image }) {
                this.result = { coordinates,  image };
            },
            cropImage() {
                let vm = this;
                const { canvas } = this.$refs.cropper.getResult();
                this.profile.image = canvas.toDataURL();
                if (canvas) {
                    const form = new FormData();
                    canvas.toBlob(blob => {
                        form.append('file', blob);
                        form.append('id', vm.currentUser.id);
                        this.$store.dispatch(USER_UPLOAD_IMAGE, form)
                            .then((response) => {
                                vm.$message.success(response.message);
                            }).catch((error) => {
                                vm.$message.warning(error.message);
                            });
                        this.dialogFormVisible = false;
                    }, 'image/jpeg',0.8);
                }
            },
            reset() {
                this.image = {
                    src: null,
                    type: null,
                };
            },
            closed () {
                this.$refs.input.value = '';
            },
            loadImage(event) {
                const {files} = event.target;
                // Ensure that you have a file before attempting to read it
                if (files && files[0]) {
                    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                    if (this.image.src) {
                        URL.revokeObjectURL(this.image.src)
                    }
                    // 2. Create the blob link to the file to optimize performance:
                    const blob = URL.createObjectURL(files[0]);

                    // Create a new FileReader to read this image binary data
                    const reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                        this.image = {
                            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                            src: blob,
                            // Determine the image type to preserve it during the extracting the image from canvas:
                            type: getMimeType(e.target.result, files[0].type),
                        };
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsArrayBuffer(files[0]);
                }
                this.dialogFormVisible = true;
            },
            checkAccount(account_type_id)
            {
                let arrayAccount = [14, 15, 16, 32];
                return arrayAccount.includes(account_type_id);
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        }
    };

    // This function is used to detect the actual image type,
    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }
</script>
